<template>
  <div class="content" id="parent">
    <div class="content__inner">
      <div class="content__box">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const foo = "bar!";

    return {
      foo,
    };
  },
});
</script>

import { createStore } from "vuex";
import { main, Store as MainStore, State as MainState } from "./main";

export type State = {
  main: MainState;
};

export type Store = MainStore<Pick<State, "main">>;

export const store = createStore({
  modules: {
    main,
  },
});

export function useStore(): Store {
  return store as Store;
}

export default store;

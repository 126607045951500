import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Main from "../layouts/main/Main.vue";
import Home from "../views/home/Home.vue";
import About from "../views/about/About.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Main,
    children: [
      {
        path: "",
        name: "home",
        component: Home,
      },
      {
        path: "about/",
        name: "about",
        component: About,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

export default router;

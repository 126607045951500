
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const foo = "bar";

    return {
      foo,
    };
  },
});

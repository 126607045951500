import axios, { AxiosPromise } from "axios";
import { App } from "vue";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 30000,
});

const doSearch = (
  query: string,
  category: string,
  sort: string
): AxiosPromise => {
  let params = "";

  if (category.trim() !== "") {
    params = params + "&category=" + category.trim();
  }

  if (sort.trim() !== "") {
    params = params + "&sort=" + sort.trim();
  }

  return apiClient.get("/api/search?query=" + query + params);
};

export const client = {
  doSearch,
};

export default (app: App): void => {
  app.provide("api", {
    doSearch,
  });
};

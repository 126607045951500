<template>
  <header class="header">
    <div class="header__inner">
      <div class="header__bar top-bar-boxed">
        <div class="header__home">
          <router-link :to="{ name: 'home' }" tag="a" class="header__home-link">
            <span class="header__home-text"> CebAI-BMZ </span>
          </router-link>
        </div>
        <div class="header__fill"></div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const foo = "bar";

    return {
      foo,
    };
  },
});
</script>

<template>
  <div>About view</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import {useStore} from "@/store";

export default defineComponent({
  components: {},
  setup() {
    return {};
  },
});
</script>

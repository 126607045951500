<template>
  <nav class="main-nav">
    <div class="main-nav__inner">
      <router-link :to="{ name: 'home' }" class="main-nav__link" tag="a">
        Home
      </router-link>
      <router-link :to="{ name: 'about' }" class="main-nav__link" tag="a">
        About
      </router-link>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const foo = "bar";

    return {
      foo,
    };
  },
});
</script>

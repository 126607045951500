
import { defineComponent } from "vue";
// import {useStore} from "@/store";

export default defineComponent({
  components: {},
  setup() {
    return {};
  },
});

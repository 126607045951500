import { App } from "vue";
import api from "./api";
import helper from "./helper";
import lodash from "./lodash";

export default (app: App): void => {
  app.use(api);
  app.use(helper);
  app.use(lodash);
};

<template>
  <footer class="footer">
    <p>(Footer) {{ foo }}</p>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const foo = "bar!";

    return {
      foo,
    };
  },
});
</script>

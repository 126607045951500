<template>
  <Header />
  <MainNav />

  <Content>
    <router-view />
  </Content>

  <Footer />
</template>

<script lang="ts">
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import MainNav from "@/components/main-nav/MainNav.vue";
import Content from "@/components/content/Content.vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Header,
    Footer,
    MainNav,
    Content,
  },
  props: [],
  setup() {
    const router = useRouter();

    return {
      router,
    };
  },
});
</script>
